var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createElement = createElement;

function createElement(name) {
  return document.createElementNS("http://www.w3.org/2000/svg", name);
}

exports.default = {
  createElement: createElement
};
export default exports;